
















import Vue from 'vue'

import Modal from '../../../components/Modal.vue'

export default Vue.extend({
  name: 'folderDeleteFolder',
  components: { Modal },
  props: ['projectId'],
  methods: {
    deleteProject () {
      this.$emit('delete-folder', this.projectId)
    }
  }
})
