






















































































































































































































































































import NewUiCheckbox from '@/components/NewUIComponents/NewUiCheckbox.vue'
import NewUiHeaderCellSortable from '@/components/NewUIComponents/NewUiHeaderCellSortable.vue'
import NewUiSwitcher from '@/components/NewUIComponents/NewUiSwitcher.vue'
import Vue from 'vue'
import draggable from 'vuedraggable'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import EnableRuleWithoutAdAccountModal from '@/components/strategies/modal/EnableRuleWithoutAdAccountModal.vue'
import {
  METRIC_TYPE_CUSTOM,
  OPERATORS,
  CONDITION_TYPE_CONDITION,
  COMPARE_WITH_TOP_PERCENT,
  COMPARE_WITH_BOTTOM_PERCENT,
  COMPARE_WITH_VALUES, LINKEDIN_SERVICE, FACEBOOK_SERVICE,
  SELECTION_METHODS
} from '@/constants/FbAutomatedRule'

export default Vue.extend({
  name: 'AutomatedRulesRulesTable',
  components: { NewUiHeaderCellSortable, NewUiSwitcher, NewUiCheckbox, draggable },
  data () {
    return {
      operators: OPERATORS,
      duplicateDisabled: false
    }
  },
  props: {
    listRules: {
      type: Array
    },
    ruleId: {
      type: Number
    },
    folderId: {
      type: String
    },
    sort: {
      type: Boolean,
      default: true
    },
    sortSettings: {
      type: Object
    },
    page: {
      default: null
    },
    search: {
      type: Object,
      default: () => { return {} }
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('rules', ['selectedRules']),
    ...mapGetters('rules', ['getAllRules']),
    ...mapGetters('rulesFolders', ['getRulesByFolderId']),
    ...mapGetters({
      adAccountById: 'adAccounts/getById',
      entitiesStatus: 'rules/entitiesStatus',
      selectedMetric: 'rulesMeta/selectedTrigger',
      getNameCustomMetric: 'rulesMeta/getNameCustomMetric'
    }),
    ...mapGetters('adService', { service: 'getService' }),
    listRulesIds () {
      const arr = []
      this.listRules.forEach(item => {
        arr.push(Number(item.id))
      })
      return arr
    }
  },
  methods: {
    ...mapMutations('rules', ['setSelectedRules', 'removeSelectedRules', 'setRuleProp']),
    ...mapActions('rules', ['updateRule', 'duplicateRule', 'loadRules', 'changeStatuses']),
    ...mapActions('rulesFolders', { setFolderRuleProp: 'setRuleProp', loadFolderRules: 'loadFolderRules' }),
    ...mapActions('notifications', ['addExceptions']),
    selectAll () {
      if (this.listRulesIds.every((e) => this.selectedRules.includes(e)) && this.selectedRules.length > 0) {
        this.listRules.forEach((item) => {
          if (this.selectedRules.includes(Number(item.id))) {
            this.removeSelectedRules(Number(item.id))
          }
        })
      } else {
        this.listRules.forEach((item) => {
          if (!this.selectedRules.includes(Number(item.id))) {
            this.setSelectedRules(Number(item.id))
          }
        })
      }
    },
    toggleRule (id: string) {
      if (!this.selectedRules.includes(Number(id))) {
        this.setSelectedRules(id)
      } else {
        this.removeSelectedRules(id)
      }
    },
    duplicateVisible (rule) {
      return this.service === FACEBOOK_SERVICE && rule.ad_account_id
    },
    changeStatus (rule, status) {
      let newStatus
      if (status === 'ENABLED') {
        newStatus = 'DISABLED'
      } else {
        newStatus = 'ENABLED'
      }
      if (!rule.ad_account_id) {
        this.$modal.show(
          EnableRuleWithoutAdAccountModal,
          {},
          {
            name: 'enable-rule-without-ad-account',
            height: 'auto',
            width: '700',
            adaptive: true,
            classes: 'ProfileFeedbackModal',
            overlayTransition: 'linear'
          }
        )
      } else {
        this.changeStatuses({ data: { ids: [rule.id], status: newStatus } })
          .then(() => {
            this.setRuleProp({
              id: rule.id,
              type: 'status',
              value: newStatus
            })
            this.setFolderRuleProp({
              rule_id: rule.id,
              type: 'status',
              value: newStatus
            })
          })
          .catch(er => {
            if (er.response && er.response.data.errors) {
              this.addExceptions(er.response.data)
            }
          })
      }
    },
    accountName (rule) {
      const account = this.adAccountById(rule.ad_account_id)
      return account ? account.name : null
    },
    accountExternalId (rule) {
      const account = this.adAccountById(rule.ad_account_id)
      return account ? account.external_id : rule.ad_account_external_id
    },
    sortBy (column) {
      this.$emit('sortBy', { column: column })
    },
    entities (rule) {
      const ent = rule.entities
      let s = ''

      if (rule.selection_method === SELECTION_METHODS.SELECTIONS_FILTER) {
        return 'Selected filters'
      }

      if (ent.campaigns.length > 0) {
        const ending = this.entitiesLabel('campaigns', ent.campaigns.length)
        s += `Selected ${ent.campaigns.length} ${ending}`
      }
      if (ent.ad_sets?.length > 0) {
        const ending = this.entitiesLabel('ad sets', ent.ad_sets.length)
        s += `Selected ${ent.ad_sets.length} ${ending}`
      }
      if (ent.ads?.length > 0) {
        const ending = this.entitiesLabel('ads', ent.ads.length)
        s += `Selected ${ent.ads.length} ${ending}`
      }
      // for google
      if (ent.extensions?.length > 0) {
        const ending = this.entitiesLabel('extensions', ent.extensions.length)
        s += `Selected ${ent.extensions.length} ${ending}`
      }
      if (ent.search_terms?.length > 0) {
        const ending = this.entitiesLabel('search terms', ent.search_terms.length)
        s += `Selected ${ent.search_terms.length} ${ending}`
      }
      if (ent.keywords?.length > 0) {
        const ending = this.entitiesLabel('keywords', ent.keywords.length)
        s += `Selected ${ent.keywords.length} ${ending}`
      }
      return s ? _.trimEnd(s.trim(), ',') : ''
    },
    entitiesLabel (type: string, count: number): string {
      let label = type
      switch (type) {
        case 'campaigns':
          switch (this.service) {
            case LINKEDIN_SERVICE:
              label = 'campaign groups'
              break
            default:
              break
          }
          break
        case 'ad sets':
          switch (this.service) {
            case LINKEDIN_SERVICE:
              label = 'campaigns'
              break
            default:
              break
          }
          break
        default:
          break
      }
      return count === 1 ? ` ${label.slice(0, -1)}, ` : ` ${label}, `
    },
    getConditionDescription (id) {
      let rule = this.getAllRules.find(r => r.id === id)
      if (!rule) {
        rule = this.getRulesByFolderId(this.folderId).find(r => r.id === id)
      }
      const conditionsInfo = rule.conditions.conditions
      const conds = []
      Object.keys(conditionsInfo).map(item => {
        if (conditionsInfo[item].type === CONDITION_TYPE_CONDITION) {
          conds.push(conditionsInfo[item])
        }
      })
      if (conds[0] && (conds[0].metric || conds[0].metric_type === METRIC_TYPE_CUSTOM)) {
        const firstCond = conds[0]
        let metric = ''
        const selectedMetricObject = this.selectedMetric(firstCond.metric)
        if (conds[0].metric) {
          if (selectedMetricObject) {
            metric = selectedMetricObject.name.replace(/_/gi, ' ')
          } else {
            metric = firstCond.metric.replace(/_/gi, ' ')
          }
        }
        // new variable to determine the type of metric (taking into account the custom one)
        const customMetricType = firstCond.metric_type
        // old name of metric type (excluding custom metric)
        const metricType =
          (selectedMetricObject &&
            selectedMetricObject.type) ||
          'numeric'
        let value
        if (
          firstCond.operator === 'IN_RANGE' ||
          firstCond.operator === 'NOT_IN_RANGE'
        ) {
          if (metricType === 'datetime') {
            value = `${moment
              .unix(firstCond.value[0])
              .format('MM/DD/YYYY HH:MM')} and ${moment
              .unix(firstCond.value[1])
              .format('MM/DD/YYYY HH:MM')}`
          } else {
            value = `${firstCond.value[0]} and ${firstCond.value[1]}`
          }
        } else if (
          firstCond.operator === 'IN' ||
          firstCond.operator === 'NOT_IN'
        ) {
          if (metricType === 'dropdown') {
            value = selectedMetricObject.values[firstCond.value[0]]
          } else {
            value = `${firstCond.value[0].replace(/_/gi, ' ').toLowerCase()}`
          }
          if (firstCond.value.length > 1) {
            value = ` one of ${firstCond.value.length} items`
          }
        } else if (metricType === 'datetime') {
          if (typeof firstCond.value === 'object') {
            value = firstCond.value.time
          } else {
            value = moment.unix(firstCond.value).format('MM/DD/YYYY HH:MM')
          }
        } else {
          value = firstCond.value
        }
        // TODO: это надо отрефакторить, разделить на части
        if (typeof value === 'object') {
          if (Object.prototype.hasOwnProperty.call(value, 'ranking_type')) {
            if (firstCond.value.ranking_type === COMPARE_WITH_TOP_PERCENT ||
              firstCond.value.ranking_type === COMPARE_WITH_BOTTOM_PERCENT) {
              value = `${value.value}% ${COMPARE_WITH_VALUES[value.ranking_type]}`
            } else {
              value = `${value.value} of ${COMPARE_WITH_VALUES[value.ranking_type]}`
            }
          }
        }

        // output of the rudder description taking into account the fact that there is a custom metric inside
        // the name of the custom metric is determined using the "getNameCustomMetric" getter,
        // but only if the custom metric comes first - in other cases it will be added to the 'and ... more'
        let message
        if (customMetricType === METRIC_TYPE_CUSTOM) {
          message =
            'Custom metric ' +
            this.getNameCustomMetric(firstCond.custom_metric_id)
        } else {
          message = `If ${metric} ${
            this.operators[firstCond.operator]
          } ${value}`
        }
        // add a postscript if there are more than 1 conditions
        if (conds.length > 1) {
          message += ` and ${conds.length - 1} more`
        }
        return message
      }
      return ''
    },
    deleteRuleConfirm (ruleId) {
      this.$emit('delete-rule-confirm', ruleId)
    },
    duplicateRuleHandler (ruleId) {
      this.duplicateDisabled = true
      this.duplicateRule({ id: ruleId }).then((response) => {
        if (response.data.folder_id) {
          this.loadFolderRules({ folder_id: response.data.folder_id })
        } else {
          this.loadRules({
            search: this.search,
            page: this.page
          })
        }
      }).finally(() => {
        this.duplicateDisabled = false
      })
    }
  }
})
