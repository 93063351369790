




















import { PropType } from 'vue'

export default {
  name: 'NewUiHeaderCellSortable',
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    sortState: {
      type: String as PropType<'asc' | 'desc' | ''>,
      default: ''
    },
    sortKey: {
      type: String,
      default: 'label'
    }
  },
  methods: {
    toggleSort (): void {
      this.$emit('toggle-sort', this.sortKey)
    }
  }
}
