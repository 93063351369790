




































import Modal from '../../Modal.vue'
import Vue from 'vue'
export default Vue.extend({
  name: 'ModalRenameFolder',
  components: { Modal }
})
