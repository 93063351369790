
























import Modal from '../../Modal.vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import { messages } from '@/i18n/messages'
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
  name: 'RenameFolderModal',
  components: { Modal, BaseInputVuelidate },
  data () {
    return {
      new_name: this.folder.name ? this.folder.name : '',
      showError: false
    }
  },
  validations: {
    new_name: {
      required
    }
  },
  i18n: {
    messages
  },
  props: ['folder'],
  methods: {
    closeModal () {
      this.$modal.hide('create-folder-modal')
    },
    renameModal () {
      this.$emit('rename-folder', { name: this.new_name, id: this.folder.id })
    }
  },
  computed: {
    ...mapGetters('rulesFolders', ['getError'])
  }
})
