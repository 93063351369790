


























import Modal from '../../Modal.vue'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { messages } from '@/i18n/messages'
import Vue from 'vue'
export default Vue.extend({
  name: 'ModalRenameFolder',
  components: { Modal, BaseInputVuelidate },
  data () {
    return {
      name_folder: ''
    }
  },
  validations: {
    name_folder: {
      required
    }
  },
  i18n: {
    messages
  },
  methods: {
    ...mapMutations('rulesFolders', ['setFolderStateProp', 'setProcessing']),
    ...mapActions('rulesFolders', ['setName']),
    closeModal () {
      this.$modal.hide('create-folder-modal')
    },
    createModal () {
      this.setProcessing({ processing: true })
      this.$emit('create-folder', null)
    },
    setFolderName (event) {
      this.name_folder = event.value
      this.setName(event.value)
    }
    // createFolder () {
    //   this.$store
    //     .dispatch('fb_automated_rules_folders/createFolder')
    //     .then(res => {
    //       if (res.status === 200) {
    //         this.closeModal()
    //         this.$store.dispatch('fb_automated_rules_folders/getAllFolders')
    //       }
    //     })
    // },
    // changeName (acc) {
    //   this.new_name = acc
    // },
    // leaveField () {
    //   this.showError = true
    // }
  },
  computed: {
    ...mapGetters('rulesFolders', ['getError', 'getProcessing']),
    isDisabled () {
      return !this.$store.state.rulesFolders.name || !this.$store.state.rulesFolders.name.length || this.getProcessing
    }
    // ...mapState('fb_automated_rules_folders', ['nameFolder'])
    // createNameError: function () {
    //   if (this.nameFolder.trim().length === 0 && this.showError) {
    //     return 'The name field is required to create a new folder'
    //   }
    //   return ''
    // }
  }
})
