




















import Vue from 'vue'
import Modal from '@/components/DynamicModal.vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'EnableRuleWithoutAdAccountModal',
  components: { Modal },
  computed: {
    ...mapState('rules', ['selectedRules'])
  },
  methods: {
    close () {
      this.$modal.hide('enable-rule-without-ad-account')
    }
  }
})
