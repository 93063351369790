

































import Vue from 'vue'

import Modal from '../../../components/Modal.vue'

export default Vue.extend({
  name: 'RulesGroupDelete',
  components: { Modal },
  props: {
    selectedRules: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    deleteRulesGroup () {
      this.$emit('delete-group-folder')
    }
  }
})
