































import Modal from '../../Modal.vue'
import VSelect from '../../../components/BaseComponents/VSelect/VSelect.vue'
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
export default Vue.extend({
  name: 'ModalRenameFolder',
  components: { Modal, VSelect },
  data () {
    return {
      selected: null,
      showError: false
    }
  },
  methods: {
    ...mapMutations('rulesFolders', ['setFolderStateProp']),
    addRules () {
      this.$emit('add-rules', this.selected)
    }
  },
  computed: {
    ...mapGetters('rulesFolders', ['getAllFolders'])
  },
  mounted () {
    this.selected = this.getAllFolders.data[0]
  }
})
