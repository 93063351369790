




































































import draggable from 'vuedraggable'
import BaseDropdown from '../BaseComponents/BaseDropdown/BaseDropdown.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'AutomatedRulesFolder',
  components: { BaseDropdown, draggable },
  props: {
    folder: {
      required: true
    }
  },
  computed: {
    empty (): boolean {
      return !this.folder.rules.length
    }
  },
  methods: {
    ...mapActions('rulesFolders', ['toggleFolder']),
    ...mapMutations('rulesFolders', ['setFolderReactivity']),
    toggleFolderButton (): void {
      this.toggleFolder(this.folder.id)
    },
    renameFolder (): void {
      this.$emit('renameFolder')
    },
    deleteFolder (): void {
      this.$emit('deleteFolder', this.folder.id)
    }
  }
}
