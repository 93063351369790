


































































































































































































































import NewUiPagination from '@/components/NewUIComponents/NewUiPagination.vue'
import Vue from 'vue'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import AutomatedRulesFolder from '@/components/strategies/AutomatedRulesFolder.vue'
import moment from 'moment'
import Input from '@/components/BaseInputVuelidate.vue'
import FolderCreateModal from '@/components/strategies/modal/FolderCreateModal.vue'
import FolderDeleteModal from '@/components/strategies/modal/FolderDeleteModal.vue'
import FolderRenameModal from '@/components/strategies/modal/FolderRenameModal.vue'
import RuleDeleteModal from '@/components/strategies/modal/RuleDeleteModal.vue'
import RulesGroupDeleteModal from '@/components/strategies/modal/RulesGroupDeleteModal.vue'
import RuleCreateSchemeModal from '@/components/strategies/modal/RuleCreateSchemeModal.vue'
import FolderAddingRules from '@/components/strategies/modal/FolderAddingRules.vue'
import AutomatedRulesRulesTable from '@/components/strategies/AutomatedRulesRulesTable.vue'
import EnableRuleWithoutAdAccountModal from '@/components/strategies/modal/EnableRuleWithoutAdAccountModal.vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
import { STATUS_HASNT_PERMISSION, STATUS_UNAUTHENTICATED } from '@/constants/AdAccount'
import _ from 'lodash'
import {
  CONDITION_TYPE_CONDITION, FACEBOOK_SERVICE, LINKEDIN_SERVICE, OPERATORS, SNAPCHAT_SERVICE, TIKTOK_SERVICE,
  GOOGLE_SERVICE, TWITTER_SERVICE
} from '@/constants/FbAutomatedRule'

interface IActiveFilter {
  label: string
  value: string | boolean
  field: string
}

const activeFilterInitialValue: IActiveFilter = {
  label: 'All',
  value: 'ALL',
  field: 'status'
}

export default Vue.extend({
  name: 'AutomatedRules',
  components: {
    NewUiPagination,
    AutomatedRulesFolder,
    Input,
    FolderCreateModal,
    FolderDeleteModal,
    FolderRenameModal,
    RuleCreateSchemeModal,
    RuleDeleteModal,
    FolderAddingRules,
    RulesGroupDeleteModal,
    AutomatedRulesRulesTable,
    BasePagePreloader,
    VSelect,
    BaseAlert
  },
  data () {
    return {
      page: 1,
      per_page: 15,
      sortSettings: {
        column: 'created_at',
        order: 'desc'
      },
      loading: true,
      editableFolder: { name: '' },
      editableRule: null,
      searchQuery: null,
      searchFilter: ['All Rules', 'Active', 'Disabled'],
      filters: [
        {
          label: 'All',
          value: 'ALL',
          field: 'status'
        },
        {
          label: 'Enabled',
          value: 'ENABLED',
          field: 'status'
        },
        {
          label: 'Triggered',
          value: true,
          field: 'triggered'
        }
      ],
      activeFilter: activeFilterInitialValue,
      allFilterKeys: [],
      operators: OPERATORS,
      hideAlertBlock: false,
      intervalId: 0,
      clientY: document.defaultView?.innerHeight / 2 || 128
    }
  },
  props: {
    service: {
      required: true,
      type: String
    },
    loadFolderCallback: {
      type: Function
    }
  },
  mounted () {
    this.setService(this.service)
      .then(() => {
        this.onMount()
          .then(() => {
            if (this.loadFolderCallback) {
              this.loadFolderCallback()
            }
          })
      })
  },

  watch: {
    searchQuery: _.debounce(function () {
      this.page = 1
      if (this.searchQuery) {
        this.getRulesWithFolders()
        this.getRulesWithoutFolder()
      } else {
        this.onMount()
      }
    }, 600),
    activeFilter (v) {
      this.page = 1
      if (v.value !== 'ALL') {
        this.getRulesWithFolders()
        this.getRulesWithoutFolder()
      } else {
        this.onMount()
      }
    },
    service (v) {
      this.setService(v).then(() => {
        if (this.activeFilterEqualsInitial) {
          this.page = 1
          this.onMount()
        } else {
          this.activeFilter = activeFilterInitialValue
        }
      })
    }
  },

  computed: {
    ...mapState('rules', {
      selectedRules: 'selectedRules'
    }),
    ...mapGetters(['get_width']),
    ...mapGetters('rules', ['pagination', 'getAllRules', 'entitiesStatus', 'getRuleById']),
    ...mapGetters({ ad_accounts: 'adAccounts/activeAccounts' }),
    ...mapGetters({
      selectedMetric: 'rulesMeta/selectedTrigger',
      adAccountById: 'adAccounts/getById',
      getAllFolders: 'rulesFolders/getAllFolders',
      getFolderRuleById: 'rulesFolders/getFolderRuleById',
      getAllAccounts: 'adAccounts/getAllAccounts',
      getRuleFromFoldersById: 'rulesFolders/getRuleById'
    }),
    ...mapGetters('adService', ['getServiceTitle', 'getServiceTitles', 'getServiceIconSrc']),
    activeFilterEqualsInitial () {
      return this.activeFilter.value === activeFilterInitialValue.value &&
        this.activeFilter.field === activeFilterInitialValue.field &&
        this.activeFilter.label === activeFilterInitialValue.label
    },
    filterForSearch () {
      if (this.searchFilter[0] === 'Active') return 'ENABLED'
      else if (this.searchFilter[0] === 'Disabled') return 'DISABLED'
      else return null
    },
    showAlert () {
      if (this.hideAlertBlock) return false
      return this.getAllAccounts.some(account => account.status === STATUS_UNAUTHENTICATED || account.status === STATUS_HASNT_PERMISSION)
    },
    defaultSearchParams () {
      return {
        status: this.filterForSearch,
        folder_id: 'null',
        order_by: this.sortSettings.column || 'created_at',
        order: this.sortSettings.order || 'desc',
        per_page: this.per_page
      }
    }
  },
  methods: {
    ...mapMutations('rules', ['resetSelectedRules']),
    ...mapActions('rulesFolders', ['loadFolders', 'creatingFolder', 'renamingFolder', 'deletingFolder']),
    ...mapActions('rulesFolders', { setFolderRulesStatus: 'setRulesStatus' }),
    ...mapActions('rules', [
      'loadRules', 'loadRulesWithFolders', 'deleteRuleItem', 'changeStatuses',
      'deleteManyRules', 'setRulesStatus', 'moveRulesToFolder'
    ]),
    ...mapActions('adAccounts', ['loadAccounts']),
    ...mapActions('rulesMeta', ['loadAllData']),
    ...mapActions('notifications', ['addSuccessNotification', 'addExceptions']),
    ...mapActions('adService', ['setService']),
    onMount () {
      this.loading = true
      this.allFilterKeys = this.searchFilter
      const promises = [
        // get automated rules with folder id
        this.loadFolders({}),
        this.getRulesWithoutFolder(this.page),
        this.loadAccounts({ search: { service: this.service, target: 'ads_management' } }),
        this.loadAllData({})
      ]
      return Promise.allSettled(promises)
        .then(() => {
          this.clearSelected()
          this.loading = false
        })
    },
    closeAlert () {
      this.hideAlertBlock = true
    },
    show_create_rule_modal () {
      this.$modal.show('rule-create-scheme-modal')
    },
    renameFolder (event) {
      this.renamingFolder({ data: event }).then(() => this.$modal.hide('rename-folder-modal'))
    },
    changePage (acc) {
      this.page = acc
      this.getRulesWithoutFolder(this.page, this.per_page, this.sortSettings.column, this.sortSettings.order)
    },
    changePerPage (acc) {
      this.per_page = acc
      this.getRulesWithoutFolder(this.page, acc, this.sortSettings.column, this.sortSettings.order)
    },
    deleteFolder (folderId) {
      this.deletingFolder({ id: folderId }).then(() => {
        this.$modal.hide('delete-folder-modal')
        this.clearSelected()
      })
    },
    sortBy (column) {
      this.sortSettings.column = column
      if (this.sortSettings.order === '') {
        this.sortSettings.order = 'asc'
      } else if (this.sortSettings.order === 'asc') {
        this.sortSettings.order = 'desc'
      } else if (this.sortSettings.order === 'desc') {
        this.sortSettings.order = 'asc'
      }
      this.getRulesWithoutFolder(this.page, this.per_page, column, this.sortSettings.order)
    },
    getRuleFromRulesAndFolders (id) {
      let rule = this.getRuleById(id)
      if (!rule) {
        rule = this.getRuleFromFoldersById(id)
      }
      return rule
    },
    toggleStatusOfRulesGroup (status) {
      if (status === 'ENABLED') {
        if (this.selectedRules.some(r => !this.getRuleFromRulesAndFolders(r)?.ad_account_id)) {
          this.$modal.show(
            EnableRuleWithoutAdAccountModal,
            {},
            {
              name: 'enable-rule-without-ad-account',
              height: 'auto',
              width: '600',
              adaptive: true,
              classes: 'ProfileFeedbackModal',
              overlayTransition: 'linear'
            }
          )
        }
      }
      const rulesForUpdate = this.selectedRules.filter(r => this.getRuleFromRulesAndFolders(r)?.ad_account_id)
      if (rulesForUpdate.length > 0) {
        this.changeStatuses({ data: { ids: rulesForUpdate, status: status } })
          .then(() => {
            this.addSuccessNotification('Success! The status will be updated.')
            // костыль, с этим надо что-то получше придумать
            this.setRulesStatus({ ids: rulesForUpdate, status: status })
            this.setFolderRulesStatus({ ids: rulesForUpdate, status: status })
            this.clearSelected()
          })
          .catch(er => {
            if (er.response && er.response.data.errors) {
              this.addExceptions(er.response.data)
            }
          })
      }
    },
    searching (acc) {
      this.searchQuery = acc.value
    },
    createRule () {
      switch (this.service) {
        case FACEBOOK_SERVICE:
          this.$router.push({ name: 'CreateRuleFacebook' })
          break
        case SNAPCHAT_SERVICE:
          this.$router.push({ name: 'CreateRuleSnapchat' })
          break
        case GOOGLE_SERVICE:
          this.$router.push({ name: 'CreateRuleGoogle' })
          break
        case TIKTOK_SERVICE:
          this.$router.push({ name: 'CreateRuleTiktok' })
          break
        case LINKEDIN_SERVICE:
          this.$router.push({ name: 'CreateRuleLinkedin' })
          break
        case TWITTER_SERVICE:
          this.$router.push({ name: 'CreateRuleTwitter' })
          break
      }
    },
    createFromStrategies () {
      this.$router.push({ name: 'Strategies', params: { service: this.service } })
    },
    showCreateFolder () {
      this.$modal.show('create-folder-modal')
    },
    createFolder () {
      this.creatingFolder({}).then(() => this.$modal.hide('create-folder-modal'))
    },
    openModal () {
      this.$modal.show('folder-modal')
    },
    closeModal () {
      this.$modal.hide('folder-modal')
    },
    rename (folder) {
      this.editableFolder = folder
      this.$modal.show('rename-folder-modal')
    },
    showDeleteWarning (folder) {
      this.editableFolder = folder
      this.$modal.show('delete-folder-modal')
    },
    clearSelected () {
      this.resetSelectedRules()
    },
    showDeleteMany () {
      this.$modal.show('delete-group-rules-modal')
    },
    deleteSelectedRules () {
      this.$modal.hide('delete-group-rules-modal')
      this.$emit('setLoader', true)
      this.deleteManyRules({ ids: this.selectedRules })
        .then(() => {
          this.addSuccessNotification('Success!')
          this.getRulesWithoutFolder(this.page)
          this.loadFolders({})
          this.clearSelected()
        }).catch(er => {
          if (er.response && er.response.data.errors) {
            this.addExceptions(er.response.data)
          }
        }).finally(() => this.$emit('setLoader', false))
    },
    getRulesWithoutFolder (page = this.page, per_page = this.per_page, order_by = 'created_at', order = 'desc') {
      const searchObj = {
        folder_id: 'null',
        order_by: order_by,
        order: order,
        per_page: per_page,
        name: this.searchQuery
      }
      if (this.activeFilter.value !== 'ALL') {
        searchObj[this.activeFilter.field] = this.activeFilter.value
      }
      return this.loadRules({
        search: searchObj,
        page: page
      })
    },
    getRulesWithFolders (per_page = this.per_page, order_by = 'created_at', order = 'desc') {
      this.loading = true
      const searchObj = {
        name: this.searchQuery,
        order_by: order_by,
        order: order,
        include: ['folder'],
        folder_id: 'not_null'
      }
      if (this.activeFilter.value !== 'ALL') {
        searchObj[this.activeFilter.field] = this.activeFilter.value
      }
      return this.loadRulesWithFolders({
        search: searchObj,
        page: 1
      }).finally(() => {
        this.loading = false
      })
    },

    deleteRule (id) {
      this.deleteRuleItem({ id: id }).then(() => {
        this.$modal.hide('delete-rule-modal')
        this.getRulesWithoutFolder(this.page)
        this.loadFolders({})
        this.clearSelected()
      })
    },

    getConditionDescription (id) {
      const rule = this.getRuleById(id)
      const conditionsInfo = rule.conditions.conditions
      const conditions = []

      Object.keys(conditionsInfo).map(item => {
        if (conditionsInfo[item].type === CONDITION_TYPE_CONDITION) conditions.push(conditionsInfo[item])
      })

      if (conditions[0] && conditions[0].metric) {
        const firstCond = conditions[0]
        const metric = firstCond.metric.replace(/_/gi, ' ')
        const metricType =
          (this.selectedMetric(firstCond.metric) &&
            this.selectedMetric(firstCond.metric).type) ||
          'numeric'

        let value

        if (
          firstCond.operator === 'IN_RANGE' ||
          firstCond.operator === 'NOT_IN_RANGE'
        ) {
          value = `${moment.unix(firstCond.value[0]).format('MM/DD/YYYY HH:MM')} and ${moment.unix(firstCond.value[1]).format('MM/DD/YYYY HH:MM')}`
        } else if (
          firstCond.operator === 'IN' ||
          firstCond.operator === 'NOT_IN'
        ) {
          value = `${firstCond.value[0].replace(/_/gi, ' ').toLowerCase()}`
          if (firstCond.value.length > 1) value = ` one of ${firstCond.value.length} items`
        } else if (metricType === 'datetime') value = moment.unix(firstCond.value).format('MM/DD/YYYY HH:MM')
        else value = firstCond.value

        let message = `If ${metric} ${
          this.operators[firstCond.operator]
        } ${value}`

        if (conditions.length > 1) message += ` and ${conditions.length - 1} more`
        return message
      }

      return ''
    },
    // display selected entities
    entities (rule) {
      const ent = rule.entities
      const actionType = this.entitiesStatus(rule.id)
      let s = ''

      if (ent.all_active_campaigns) return `All ${actionType} campaigns`
      if (ent.all_active_ad_sets) return `All ${actionType} ad sets`
      if (ent.all_active_ads) return `All ${actionType} ads`

      if (ent.campaigns.length > 0) {
        s += `All ads in ${ent.campaigns.length} ${
          ent.campaigns.length > 1 ? 'campaigns' : 'campaign'
        }, `
      }
      if (ent.ad_sets.length > 0) {
        s += `All ads in ${ent.ad_sets.length} ${
          ent.ad_sets.length > 1 ? 'ad sets' : 'ad set'
        },  `
      }
      if (ent.ads.length > 0) {
        s += `${ent.ads.length} ${ent.ads.length > 1 ? 'ads' : 'ad'}`
      }
      return s ? _.trimEnd(s.trim(), ',') : ''
    },
    // get account name
    accountName (rule) {
      const account = this.adAccountById(rule.ad_account_id)
      return account ? account.name : null
    },
    addRulesToFolder (folder) {
      this.$modal.hide('folder-adding-rules-modal')
      this.moveRulesToFolder({
        ids: [...this.selectedRules],
        folder_id: folder?.id || null
      }).then(() => {
        this.onMount()
      })
    },
    ruleDragEnd (event) {
      removeEventListener('pointermove', this.pointerMoveHandler)
      removeEventListener('drag', this.pointerMoveHandler)
      clearInterval(this.intervalId)

      const ruleId = event.item?.dataset?.ruleId
      const folderId = event.to?.dataset?.folderId

      if (ruleId && event.from !== event.to) {
        this.moveRulesToFolder({
          ids: [ruleId],
          folder_id: folderId || null
        })
      }
    },
    ruleDragStart () {
      const height = document?.defaultView?.innerHeight
      const html = document.body.parentElement
      const range = 64
      addEventListener('pointermove', this.pointerMoveHandler)
      addEventListener('drag', this.pointerMoveHandler)

      this.intervalId = setInterval(() => {
        if (this.clientY && height && (this.clientY <= range || this.clientY >= height - range)) {
          const speed = this.clientY <= range
            ? -(range - Math.max(this.clientY, 1))
            : range - Math.min(height - this.clientY, height)
          const nextScrollTop = Math.min(Math.max(html.scrollTop + speed, 0), html.scrollHeight)
          if (!isNaN(nextScrollTop)) {
            setTimeout(() => {
              html.scrollTop = nextScrollTop
            })
          }
        }
      }, 50)
    },
    pointerMoveHandler (event) {
      this.clientY = event.clientY
    },
    setFilter (acc) {
      this.activeFilter = acc
    }
  },
  beforeDestroy () {
    removeEventListener('pointermove', this.pointerMoveHandler)
    removeEventListener('drag', this.pointerMoveHandler)
    clearInterval(this.intervalId)
  }
})
